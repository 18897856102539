<template>
  <div class="login_bg">
    <b-container class="login_wrap">
      <div v-if="isUpgrading" class="login_announcement">
        <div
          class="login_announcement_logo d-flex align-items-center mb-3 mb-sm-4 mb-lg-5"
        >
          <img
            class="login_announcement_logo--acme"
            src="../assets/acme-logo.png"
          />
          <div class="login_announcement_logo--title ml-2">雲端監控中心</div>
        </div>

        <div class="d-lg-flex align-items-lg-center py-0 py-sm-3 py-lg-5">
          <img
            class="login_announcement_logo--img mb-3 mb-sm-5 mb-lg-0"
            src="../assets/traffic-barrier.png"
          />
          <div>
            <div class="text text--md mb-2">尊敬的訪客</div>
            <div class="text text--sm mb-3 mb-sm-4 mb-lg-5">
              為了升級我們的物聯網設備和憑證，以及更新物聯網服務證書<br />
              網站將在：
            </div>
            <div class="text text--lg mb-2">
              4/10 晚上 10 : 00<br />
              開始暫停服務
            </div>
            <div class="text text--md mb-3 mb-sm-4 mb-lg-5">預計約兩小時</div>
            <div class="text text--sm">
              對此給您帶來的不便，我們深感抱歉<br />
              感謝您的耐心等待，敬請期待我們全新的面貌與更安全穩定的服務。
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isUpgrading" class="login_content rounded">
        <div class="login_content_logo">
          <img src="../assets/acme-logo.png" />
          <h1>雲端監控中心</h1>
        </div>

        <div class="login_content_form">
          <form action="">
            <div class="mb-2">
              <label for="account" class="mr-2">帳號:</label>
              <input
                id="account"
                name="account"
                class="filterInput login_content_input rounded"
                v-model="account"
                @keyup.13="login"
              />
            </div>

            <div>
              <label for="password" class="mr-2">密碼:</label>
              <input
                id="password"
                name="password"
                type="password"
                autocomplete="off"
                class="filterInput login_content_input rounded"
                v-model="password"
                @keyup.13="login"
              />
            </div>
          </form>

          <div class="text-center">
            <button
              type="button"
              class="confirmButton login_btn"
              @click="login"
            >
              登入
            </button>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import i18n from '../lang/lang'
import { loginApi } from '../api'
export default {
  data () {
    return {
      account: '',
      password: '',
      isUpgrading: false
    }
  },
  computed: {
    ...mapState(['userDetail'])
  },
  methods: {
    ...mapMutations(['ADD_USER_DETAIL', 'UPDATE_ROLE_ARRAY']),
    async login () {
      const login = await loginApi(this.account, this.password)
      if (login?.data?.success) {
        let userDetailData = login.data.data
        if (!userDetailData.demoList?.length) userDetailData.demoList = ['無']
        this.ADD_USER_DETAIL(userDetailData)
        localStorage.setItem('userDetail', JSON.stringify(userDetailData))
        this.$router
          .push({ path: `/${i18n.locale}/realtime` })
          .catch((err) => console.log('router push has error', err))
      } else {
        this.$swal('帳號密碼錯誤')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/global.scss";

.login {
  &_bg {
    height: 100vh;
    background-image: url("../assets/login_bg.jpg");
    background-position: center top;
    background-size: cover;
  }

  &_wrap {
    @include flexCenter;
    height: 100vh;
    @media screen and (min-width: 1920px) {
      height: 90vh;
    }
  }

  &_content {
    @include flexCenter;
    background-color: #c7d6e7be;
    flex-wrap: wrap;
    padding: 50px 55px;

    @media screen and (min-width: 768px) {
      padding: 50px 80px;
    }
    @media screen and (min-width: 992px) {
      padding: 50px 120px;
    }

    &_logo {
      text-align: center;

      img {
        width: 150px;
        @media screen and (min-width: 768px) {
          width: auto;
        }
      }

      h1 {
        color: #1a2b41;
        padding: 27px 0px 0px 0px;
        font-size: 1.5rem;
        margin: 0;

        @media screen and (min-width: 768px) {
          padding: 27px 27px 0 27px;
          font-size: 2.3rem;
        }
      }
    }

    &_form {
      margin-top: 22px;
      @media screen and (min-width: 576px) {
        margin-left: 1.5rem;
      }
    }

    &_input {
      width: 100%;
      @media screen and (min-width: 768px) {
        width: auto;
      }
    }
  }

  &_btn {
    font-size: calc(14rem / 16);
    width: 120px;
    margin-top: 1.5rem;
    @media screen and (min-width: 768px) {
      margin-left: 3rem;
      font-size: 1rem;
    }
  }

  &_announcement {
    background-color: #ffffffea;
    padding: 1.5rem;
    border-radius: 10px;

    @media screen and (min-width: 425px) {
      padding: 2rem 2rem 3rem 2rem;
    }
    @media screen and (min-width: 576px) {
      padding: 2rem 4rem 4rem 4rem;
    }
    @media screen and (min-width: 992px) {
      padding: 2rem 4rem 6rem 4rem;
    }

    &_logo {
      margin-left: 0px;
      &--acme {
        width: 80px;
        @media screen and (min-width: 576px) {
          width: 100px;
          margin-left: -32px;
        }
      }

      &--title {
        color: #595757;
        font-size: 1rem;
        font-weight: bold;

        @media screen and (min-width: 375px) {
          font-size: 1.2rem;
        }
      }

      &--img {
        max-width: 120px;
        display: block;
        margin: 0 auto;
        @media screen and (min-width: 576px) {
          max-width: 200px;
        }
        @media screen and (min-width: 992px) {
          margin-right: 4rem;
          max-width: 270px;
        }
      }
    }

    .text {
      font-family: $font-family-Noto;
      color: #4e4e4e;
      letter-spacing: 1px;

      &--sm {
        font-size: 14px;
        font-weight: 500;
        @media screen and (min-width: 576px) {
          font-size: 16px;
        }
      }
      &--md {
        font-size: 16px;
        font-weight: 700;
        @media screen and (min-width: 576px) {
          font-size: 18px;
        }
        @media screen and (min-width: 768px) {
          font-size: 20px;
        }
      }
      &--lg {
        font-size: 20px;
        font-weight: 700;
        color: #ffc225;
        @media screen and (min-width: 576px) {
          font-size: 28px;
        }
        @media screen and (min-width: 768px) {
          font-size: 36px;
        }
      }
    }
  }
}
</style>
